/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly {display:none;}
.desktopOnly {display:inline-block;}


h1, .h1 { font-size: 2em;}
h2, .h2, #homeMission {font-size: 1.4em;}
 .bigger, h3, .h3, h4, .h4 {font-size: 1.2em;}
.submenu a {font-size: 0.9em;}


#homeMission {font-weight: 700;}

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.Home #content #leftcol > div > div, body.fullWidth #leftcol > div > div, body.Apartment #leftcol > div > div{
	padding: 2em 0;
}


/* Reduce padding on some rows*/
#breadcrumbs .row, #bookForm .row{padding: 1em 0;}

#content .row, body.fullWidth #leftcol > div > div:first-of-type { padding: 0 0 2em 0;}

/*Increase padding content some rows */
#homeBlocks .row{
        padding: 4em 0;
    }

/* Restrict width on some rows and content areas that behave like rows*/
#homeMission .row, body.fullWidth #leftcol > div > div{
        max-width: 1240px;
    }


		
/*********************
SPRITE
*********************/
		

    #logo {
        width: 167px; height: 80px;
	    background-size: 167px 80px;
	}

/*********************
HEADER STYLES
*********************/

body.Home .header {
	/*height: 112.09px;*/
}

.header {

	    .row {
		    position: relative;
            min-height: 90px;
	    }

	    #logo, #accountMenu {
            transform: none;
	    }

        #logo {
            left: 0%;
            top:0.5em;
        }

	    #accountMenu {
			top: 10%;
            right:0;
	    }

        #mainMenu {
            display: inline-block;
            position: absolute;
            right: 0%;
            bottom:10%;
        }
     		
}


#bookForm.smallSearch {
        top: 90px;
	}
	
#bookForm .row {
     min-height: unset;
}

/**************************
HERO - Home
***************************/
/*body.Home #imageslider {max-height: 570px; overflow:hidden;}*/
body:not(.Home) #hero {

	.topBlock {
       img {max-width:1200px;}
    }
}/*hero*/

/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}

/*.menuWrap {
	width: 74.30939226%;
}*/
#mainMenu {
	/*padding: 1em 0 0.5em 0;*/
}
.topMenu {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    float: right;
    clear: none;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;
        display:inline;


        > li {
            display: inline-block;
            padding: 0 0.4em;

            > a {
                outline: none;
                &:hover, &:focus {
                    background: transparent;
                }

                &.parent:after {
                    content: "\f107";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    display: block;
                    float: right;
                    font-size: large;
                    padding-left: 0.3em;
                    line-height: 1.5;
                }

            }
            /*Prominent contact link*/
            &:first-child {
               
            }

            /*&:last-child {
                > a {
                    background: $color-secondary;
                    @include border-radius($small-border-radius);
                    padding: 0.5em 1em;
                    @include transition(all 0.5s ease-in-out);

                    &:hover, &:focus, &:active, &.activerootmenulink {
                        background: darken($color-secondary, 15%);
                        @include transition(all 0.5s ease-in-out);
                        color: $white;
                    }
                }
            }*/
            /*turn some of the main nav items OFF for public*/
            /*&:first-child > a{
				display:none;
			}
			
			&:nth-child(6) {
				display:none;
			}
			&:nth-child(7) {
				display:none;
			}
			*/
        }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-primary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 250px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/


/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {	
	> div {	width:50%;	}

}

/*Top blocks inner pages*/

/*body:not(.Home) .topBlock {	
	> div {	
        width: 50%;	
        align-self:center;
	}

    max-height: 445px;
    overflow: hidden;
    
}*/


ul.confidence li {
    width:50%;
}
/*Bottom Blocks*/
#bottomBlocks .block {
    margin-bottom: 2em;
    margin: 0 auto;
}

    /*layout needed for list items */
    #bottomBlocks #block_26 {

        ul {
           
            li {
                display:inline-block;
                width: 48%;
                margin-bottom: 0.5em;

            }
        }

    }

    /*spacing needed for upcoming events blocks */
    .columnlayout.eventsBlocks {
       justify-content: space-between;
       > article, > div {	width: 46%;	}
    }

	

/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

ul.responsive3 {
	max-height: none;
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				/*height:500px;*/
				
				&:before {background-color: rgba(0, 0, 0, 0.20); }
				
				img {
                        /*height: 420px;*/
                    }	
				.slideshow_caption { text-align:left; /*margin: 0 auto;padding:2em; max-width:50%;*/ }
			}
				
		}
			
	}

    .slick-slider .slick-active img{ margin-left: 0;}

    .slick-prev, .slick-next { top: 50%; }
    .slick-next { right: 50px; }
    .slick-prev { left: 50px; }
    .slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }
}


/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }

/*********************
Sticky CTA
*********************/

#stickyCTA {
	 
}