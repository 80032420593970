/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/

/*********************
GENERAL STYLES
*********************/
body {
	font-family: $sans-serif;
	font-size: 97.5%;
	line-height: 1.6;
	color: $black;
    font-weight:300;
}

.mobileOnly {display:inline-block;}
.desktopOnly {display:none;}

/********************
BODY CLASSES
style a page via class
********************/

.home {}    /* home page */


/*********************
Z_INDEX
*********************/
.cd-top { z-index: 9999;}
.header { z-index: 901;}
#bookForm { z-index: 900;}

ul.children {z-index: 800;}


/*********************
LAYOUT & GRID STYLES
*********************/

.row, body.Home #content #leftcol > div > div, .row-small, body.fullWidth #leftcol > div > div, body.Apartment #leftcol > div > div {
	margin: 0 auto;
	width: 96%;
	padding: 1em 0;
}

/* Turn off padding on some rows*/
.header .row, body.CurrentSpecials.fullWidth #content #leftcol > div .row, body#body_476 #content #leftcol > div .row  { padding: 0 0;}



/*********************
LINK STYLES
*********************/

a, a:visited {
	color: $link-color;

	/* removing text decoration from all headline links */
		text-decoration: none;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
	}

	/* on click */
	&:active {

	}

	/* mobile tap color */
	&:link {
		/*
		this highlights links on iPhones/iPads.
		so it basically works like the :hover selector
		for mobile devices.
		*/
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1, .h1, h2, .h2, h3, .h3, .siteName {
	font-family:$headings;
    font-display: fallback;
	a {
		text-decoration: none;
	}
}

.fal {font-weight:300;}

#accountMenu .h2 {font-family:$sans-serif; font-display: fallback; }

h4, .h4 {font-weight: 300;line-height: 1.2;}
strong, h1, .h1, h2, .h2, h3, .h3,  h5, .h5, h6, .h6, .topMenu .nav > li > a {font-weight: 700;}

h1, .h1, h2, .h2{margin:0.35em 0;}
h3, .h3, h5, .h5, h6, .h6{margin:0.75em 0;}
#accountMenu .h2, #address .h2, .slideshow_caption .h1,  .slideshow_caption .h2,#pushMenu p  {margin:0em 0;}

h3, .h3, h4, .h4, .siteName strong,  thead, .slideHeading {text-transform:uppercase;}

/*h3, .h3, .siteName strong, .topMenu .nav > li > a, thead {letter-spacing:1px;}*/

h1, .h1, .phone { font-size: 1.6em;}
h2, .h2, h4, .h4, .slideDescription, .fa-ul li, .bigger, .topBlock > div p, .phone, ul.confidence strong {font-size: 1.2em;}
h3, .h3, h5, .h5, h6, .h6, #breadcrumbs .siteName strong, .accountMenu {font-size: 1em;}
h3, .h3 {
    /*margin-bottom:1em;
    &:after {
            content: "";
            width: 80px;
            height: 2px;
            background-color: $color-primary;
            display: block;
            margin: 0.5em 0;
       
    }
	&.text-center:after {margin: 0.5em auto;}*/
}

#rightcol h4, #rightcol .h4 {
    position: relative;
    text-align: center;
    color: black;
    margin-bottom: 1.5em;
    
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 200%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background-color: $color-secondary;
    }  
  
    &:after {
      content: '';
      position: absolute;
      width:20%;
      height: 3px;
      top:200%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: $color-secondary;
      @include transition(all 0.2s ease-in-out);
    }
  
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }


body.Apartment #leftcol div h3:after, body.Apartment #leftcol div .h3:after {margin: 0.5em auto;  }
body.Apartment #bottomBlocks div h3:after, body.Apartment #bottomBlocks div .h3:after {margin: 0.5em auto; background-color: $white; }
.responsive .h3:after {display:none; margin-bottom:0; }

.smtext, #breadcrumbs, thead, .respond-form label, .holdForm ul { font-size: 0.85em; }


/*************************
Colours
*************************/


.red, .darkHeader #accountMenu, #accountMenu .h2, .darkHeader .phone, body.Home #content ul li::before, blockquote:before, body.Home .columnlayout h2 a:hover, h3, .h3,
.respond-form h2, .fa-li, .slideshow_caption a.button, a.button.secondary:hover, ul.confidence strong, .fa-circle {
    color: $color-primary;
}


.black, .topMenu .nav > li > a,
 a.button, button,
body.Home #content #leftcol > div:first-of-type,
ul.responsive3 .h2.slideDescription,
body.Home #content #leftcol > div:first-of-type, 
body.fullWidth #content #leftcol > div:first-of-type,
body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(odd),
body#body_476 #content #leftcol > div:nth-child(odd),
body.Apartment #leftcol > div:first-of-type, 
#breadcrumbs, body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(odd) h2 a, #breadcrumbs a
{color: $black; }

.grey
{color: lighten($black, 40%); }

.white, #pushMenu .nav li a,
.topMenu .nav li ul li a,
ul.responsive3 .slideshow_caption a,
.slick-slider button:before,
.responsive .h3,
.holdForm, .holdForm h2, .holdForm a.button,
#footer #facebook a, #copyright, #footer, #footer a, .cd-top i, #cta a.button, .button.primary, a.button.primary, a.button:hover, a.button.secondary, .button.tertiary,
#hero .slick-slider button:before,
#homeMission,
#bottomBlocks, .fa-stack, #pushMenu .sand, #pushMenu a.menu-btn.rotate {
    color: $white;
}

/*************************
Turn off printable items
*************************/	
	.printable {display: none;}
	

/*********************
SPRITE
*********************/
/*use modernizer script stop detect browser fallback for next gen images*/
/*.no-webp .box { color: red; }
.webp .box { color: green; }*/	
/*.no-js #logo, .no-webp #logo { background-image: url('../images/logo.png'); }
.webp #logo { background-image: url('../images/logo.webp'); }*/

    #logo {
		display: block;
		margin: 0 auto;
        background: transparent url('../images/logo.svg') no-repeat center top;
        background-position: center top;
        width: 125px; height: 60px;
        background-size: 125px 60px;
	}



/*********************
HEADER STYLES
*********************/

/* STICKY NAV - This is where the header is set to be sticky. 
We can also change colour and size here - effects all take place onScroll (waypoints triggers this) */



.header {	

	.clearHeader {
		position: relative;
		background-color: $white;
		@include transition(all 0.2s ease-in-out);
	}
	 
	.darkHeader {
		position: fixed;
		background-color: $white;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		@include transition(all 0.2s ease-in-out);
        top: 0;
	}
	
}

/* position header elements as needed */

.header {

    .row {min-height: 70px;}
    
    position: relative;
       

    .menu {width:100%;}

    .menu-btn, #logo, #accountMenu{
		position: absolute;
	}

    #accountMenu {
		right: 2%;
        @include center(false, true);
        .mobileOnly {margin-left:0.5em}
	}
    
    .menu-btn {
		left: 2%;
        @include center(false, true);
	}

    #logo {
		@include center(true, false);
		top: 0.25em;
	}

    #mainMenu {
        display:none;
    }

    
}



/***********************
HERO - HERO
************************/

#hero {
	position: relative;
}

body.Home #hero .row { @include center(true, true); width: 90%;}

#hero {

}

body:not(.Home) #hero {
    overflow:hidden;
    height:250px;
    img, h1, .h1{ @include center(true, true); }
    img {min-height: 250px; max-width: 200%;z-index:-1;}
    h1, .h1 { background:rgba(255, 255, 255, 0); padding:1em; width:90%;z-index:2; }

    &:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.35); z-index:0;}
}

body:not(.Home) #hero {
	position: relative;

	.topBlock {
        /*z-index: 8888;*/
        text-align: center;

        h1, .h1 {
            display: inline-block;
            padding: 0.5em;
            background: $white;
            bottom: 0;
            width: 100%;
            @include center(true, false);
        }

        img {max-width:800px;}
    }
}/*hero*/


	
/*********************
NAVIGATION STYLES
*********************/
#touchMenu,
.menu-btn {display:block;}

#mainMenu {
    display:none;
}

#pushMenu {
    ul:nth-of-type(2){
        > li:first-of-type{display:none;} /*hide home link in 2nd nav*/
    }
}

.topMenu, #pushMenu {
	/*list-style:none;
	float: right;
	clear: right;*/
		
		
		a.toggleMenu {
			width: 100%; 
			padding: 2% 4%;
			text-transform: uppercase;
			margin-bottom: 0;
				span {
					display: block; 
					width:auto; 
					float:right;
					padding-left: 0.5em;
				}
					
		}
		
		.nav {
			/*margin-top:0;
			position: absolute;
			width: 96%;
			z-index: 99999;*/
		
				li {
					position: relative;
					text-align: left;
					a {
						display: block;
						text-decoration: none;
						padding: 0.5em 0.5em;
						background: darken($black, 10%);
						border-bottom: 1px solid rgba(130, 154, 193, 0.21);
				
						&:hover, &:focus {
							background: darken($black, 5%);
						}
						&.parent:before {
							content: "\f107";
							font-family: "Font Awesome 5 Pro";
                            font-weight: 300;
							display: block;
							float: right;
							font-size: large;
							padding-right: 0.5em;
							line-height: 1.2;
						}
					}
					&.hover {
						> a.parent:before {
							content: "\f106";									
						}
					}
			
					&:first-child {}
			
					&:last-child {}
			
					/* Sub-menu level 2 */
					ul,
					ul.sub-menu,
					ul.children {
						margin-top: 0;
						border-top: 0;
						display: none;
			
						li {
			
							a {
								padding-left: 1em;
								background: darken($black, 5%);
								border-bottom: 1px solid rgba(130, 154, 193, 0.21);
											
								&:hover,
								&:focus {
									background: darken($black, 0%);
								}			
								&:link {}
								
								
							}
			
							&:first-child {}
			
							&:last-child {}
							
							/* Sub-sub-menu level 3	*/
							ul {
													
								li {
					
									a {
										padding-left: 1.5em;												
										background: $black;
										&:hover,
										&:focus {
											background: lighten($black, 5%);
										}
									}
									
									/* Sub-sub-menu level 4	*/
									ul {
															
										li {
							
											a {
												padding-left: 2em;
												background: lighten($black, 30%);
												
											}
							
										}
							
									}/* end level 4	*/
					
								}
					
							}/* end level 3	*/
			
						}
			
					}/* end level 2	*/
					
					/* Hover effects */
					&:hover ul {
						
					}
					
			
				} /* end .menu li */
			
			
		
		} /* end .nav */

        .nav > li.hover > ul { left: 0; display: block;}
        .nav li li.hover > ul { left: 100%; top: 0; display: block; }

}/* end .topMenu */



/*********************
CONTENT STYLES
*********************/

/* content area all inner pages */
#content, #homeBlocks, #bottomBlocks  {background:$white;}



.fa-ul {
    padding-left: 60px;}
	
	
			p {}


			ul, ol, table, dl {}

			ul.blue-list { list-style:none;

				li::before {
                    content: "\2022"; 
                    color: $color-primary;
                    display: inline-block; 
                    width: 1em;
                    margin-left: -1em;
                    font-size:1.25em;
				}


			}


			ol {

				li {}

			}

			blockquote {
				margin: 0 0 0 0;
				text-align: left;
								
			}
			
			blockquote {
				padding: 1em;
				p {
					padding-left: 2em;
					margin-top: 0;
				}
				h5 {
					margin: 0.4em 0;
				}
								
				&:before {
					content: "\f10d";
					font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
					display: block;
					float:left;
					padding-right: 0.5em;
					line-height: 1.2;
									
				}
				
				a {
					text-decoration: none;
				}
				
				em{
					text-align: right;
					}

			}

			dl {}

			dt {}

			dd {
				margin-left: 0;
				font-size: 0.9em;
				color: #787878;
				margin-bottom: 1.5em;
			}

			img {
				/*margin: 0 0 1.5em 0;*/
				max-width: 100%;
				height: auto;
			}


	#breadcrumbs { 
		/*border-bottom: 1px dotted lighten($color-secondary, 0%);*/
				
		> div {
            /*text-align: center;*/
             div {
			    display: inline;
            }
		}
	}


/********************************************************
Specific PAGE CONTENT STYLES (alternating row colours)
********************************************************/
body.Home #content #leftcol > div:first-of-type,
body.fullWidth #content #leftcol > div:first-of-type,
body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(odd),
body#body_476 #content #leftcol > div:nth-child(odd),
body.Apartment #leftcol > div:first-of-type,
body #breadcrumbs { /*background: lighten($color-secondary, 15%);*/
}


body.Apartment .whiteBG #leftcol > div:first-of-type{background: white;}


/*********************************
 BLOCKS
*********************************/
ul.confidence {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    padding-left: 0;
    justify-content: flex-start;
    /*turn off basic li styling*/
    padding: 0 0 0em 0;

    li {
        display: inline-block;
        vertical-align: top;
        flex: 1 1 auto;
        flex-grow: 0;
        width: 100%;
        margin: 1em 0;
    }

    p {
        margin: 0;
    }

    .goalimg {
        float: left;
        max-width: 180px;
        max-height: 80px;
        margin-right:0.5em;
    }
}


.block {
}

.columnlayout, body.Apartment #apartmentBlocks {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    padding-left: 0;
    justify-content: flex-start;
    /*turn off basic li styling*/
    padding: 1em 0 1em 0;



    > div, article {
        display: inline-block;
        vertical-align: top;
        flex: 1 1 auto;
        flex-grow: 0;
        width: 100%;
        padding: 0 1em;

        > div {
            width: auto;
        }
    }

    article.newsBlock {
        width: 100%;
    }
}

   

    /* LEFT BLOCKS - Mission statement*/
    #homeMission{
        .row {
            > div {text-align:center;}
        }
    }

    /* RIGHT BLOCKS */
    #rightcol {
        .block {
            margin-bottom:1em;
            .respond-form, #newsletter {
                form {
                    width: 100%;
                }
            }


            ol, ul {
                padding: 0em 0em 1em 0em;

                li {
                    list-style: none;
                    line-height: 1.6;
                    padding:0.25em;
                    margin-bottom:0.25em;
                    border-bottom: 1px dotted lighten($black, 30%);

                    a {
                        display: block;
                        text-decoration: none;
                        
                    }
                    a:hover, a.activesibling { }
                }

                li  {
                    &:before {
                        content: "\f105";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        display: block;
                        float: left;
                        font-size: large;
                        padding-right: 0.5em;
                        line-height: 1.2;
                    }
                }
            }
        }
        /* end .block */
    }
    /* end #rightcol */


    /*TOP BLOCKS INNER PAGES*/
  
    body:not(.Home) #topblocks {

        background-color: darken($white, 10%);

        div.block { 
            max-height: 350px;
		    position: relative;
            overflow:hidden;
            margin-bottom:0;

            > p {margin:0 0;}

            img {
              /*max-height: 500px; 
              height: 80vh;
              max-width: none;*/
              width: auto;
              height: auto;
            }
		
		    .caption {
			    @include center();
                /*background-color:rgba(255, 255, 255, 0.6);*/
			    width: 95%;
			    text-align: center;
                padding: 1em;
		    }
        }

    }
   



	
/******************************************************************
FORM STYLES
******************************************************************/
    /* show/hid fields on gift voucher form*/
    .hide {
        
    }


    .respond-form {
        margin: 0em 0em;
        padding: 0%;
        min-width: 190px;
        background-color: transparent;
    }

    .respond-form, #address {
        /* not nested as not all forms contain a table */
        table {
            width: 100%
        }

        form {
            margin: 0 0;

            li {
                list-style-type: none;
                clear: both;
                margin-bottom: 0.7335em;

                label,
                small {
                    display: none;
                }
            }

            fieldset {border:0; margin:0 0; padding: 0 0 0 0;}

            input[type=text],
            input[type=email],
            input[type=date],
            input[type=url],
            input[type=tel],
            textarea,
            select {
                padding: 1em;
                background-color:darken($white, 8%);
                margin: 0 0 1em 0;
                display: table-cell;
                width: 100%;
                max-width: 100%;
                border: none;

                &:focus {
                    /*background-color: #fff;*/
                }
                /* form validation - comment out as editor doesn't support some of the html5 validation techniques */
                /* &:invalid {
				outline: none;
				border-color: #fbc2c4;
				background-color: #f6e7eb;
				-webkit-box-shadow: none;
				   -moz-box-shadow: none;
				    -ms-box-shadow: none;
						box-shadow: none;
			}*/
            }

            input[type=checkbox] {
                margin-right: 5px;
            }

            input[name="180_LeaseEnquiry"] {
                margin-bottom: 1em;
            }


            .required {
                background-position: right top;
                background-repeat: no-repeat;
            }

            textarea {
                height: 150px;
            }

            img {
                float: left;

                &.fullwidth {
                    max-width: 100%;
                }
            }
        }
    }

    #address form {
        margin: 0 auto;
        input[type=email] {width:auto;padding: 0.5em;}
        input[type="submit"] {min-height: 36px;}
    }

    body.Apartment {
        .respond-form {
            background: transparent;

            form {
                input[type=text],
                input[type=email],
                input[type=url],
                textarea,
                select {
                    background-color: $color-quaternary;
                }
            }
        }
    }
/*********************
LEFT COLUMN
*********************/
    #leftcol {
        padding-bottom: 0em;

        #div307 {
            div {
                text-align: center;
            }
        }
    }

/*********************************************
NEWS LISTING - SPECIALS and WHAT's ON
*********************************************/
    #listing {
        list-style: none;
        > div { padding: 2em;}
    }

    body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(even),
    body#body_476 #content #leftcol > div:nth-child(even)
    {
        #listing {
            div.first { float:right; }
        }
    }

    div.listingImg {
        min-height: 100px;
    }



/*********************
GALLERY
*********************/
    #gallery {
        display: table;
        margin: 0em auto;
        padding: 0 0 0 0;

        li {
            list-style: none;
            width: 45.5%;
            margin-right: 2%;
            display: -moz-inline-stack;
            display: inline-block;
            zoom: 1;
            *display: inline;
            vertical-align: top;
            border-bottom: 1px dotted lighten($color-quaternary, 50%);
        }

        p {
            display: none;
        }
    }

    .confidence {
        padding: 2em 0;

        .row {
            > div {
                text-align: center;
            }
        }
    }

/*********************
FOOTER STYLES
*********************/
    #bottomBlocks,  #cta{background: lighten($color-primary, 0%);}

    #bottomBlocks {position:relative; padding-top:100px;}

    #footer {
        background: $black;
        /*text-align: center;*/
        > div {
           

            i {
                padding-right: 0.25em;
            }

            #copyright {
                .first {
                    a {
                        display: block;
                    }
                }
            }

            nav {
                ul {
                    li {
                        border-bottom: 1px dotted $color-quaternary;

                        a {
                            display: block;
                            line-height: 2;
                        }
                    }
                }
            }

            #privacy {
             

                a {
                    padding: 0 0.5em;

                    &:first-child {
                        border-right: 1px solid $color-quaternary;
                    }
                }
            }
        }
    }
    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    .rslides {
        position: relative;
        list-style: none;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            top: 0;
            /*padding: 2%;*/
            background: $white;
        }

        img {
            display: block;
            height: auto;
            float: left;
            width: 100%;
            border: 0;
        }
    }

    .rslides_nav {
        display: none;
        visibility: hidden;
    }

    .slides1_nav {
        display: none;
        visibility: hidden;
    }

#leftcol li.slick-slide:before,
#leftcol ul.responsive .slick-dots li:before  {display:none;}

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */
/*.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-perspective: 1000px;
}*/
/* SLIDER */
    .slick-slider {
        padding: 0;
        list-style: none;

        .slick-list {
            margin: 0 auto;

            .slick-track {

                .slick-slide {
                    position: relative;
                	padding:0;

                    .slideshow_caption {
                    }
                }
            }
        }

        button {
            padding: 0.2em;
            height: auto;
            z-index: 99;

            &:before {
                content: "\f104";
                font-family:"Font Awesome 5 Pro";
                font-weight: 300;
                height: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        .slick-next:before {
            content: "\f105";
            padding-left: 0.1em;
        }
    }

    .slick-prev, .slick-next {
        top: 48%;
        z-index: 9999;
    }

    .slick-prev {
        left: 1px;
    }

    .slick-next {
        right: 1px;
    }

    .slick-arrow:hover, .slick-arrow:focus, .slick-arrow:active {
        border: none;
    }

    .slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img {
        width: 100%;
    }

    .slick-dots li button:before, .slick-dots li.slick-active button:before {color:$white;}

    

    /*Full width slider x3 with captions, dots and arrows */
   
    ul.responsive {
        padding-bottom: 3em;
        overflow: visible;

        .slick-list {

            .slick-track {

                .slick-slide {

                   img {@include transition(all 0.3s ease-in-out);}
                    
                    &:hover {
                        img {@include transform($zoom);@include transition(all 0.3s ease-in-out);}
                    }

                    .slideshow_caption {
                        /*text-align: center;*/
				        width: 80%;
                        background-color: rgba(0, 0, 0, 0.7);
                        opacity: 1;
                        padding: 0.5em;
                        bottom:0;
                        @include center(true, false);
                        @include transition(all 0.3s ease-in-out);
                    }

                }

            }
        }

      
        .slick-prev {left: 0 }
        .slick-next {right: 0}
        .slick-dots {bottom:0; li:before {display:none;}}
    }

    /*Full width slider with captions */
    ul.responsive3 {
        margin-bottom: 0;

        .slick-list {

            .slick-track {

                .slick-slide {
					height: 420px;
                    height: 70vh;
					
					&:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.20); }

                    img {
                        /*height: 420px;*/
                        max-width: none;
                        width: auto;

                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transition: transform 6s;
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                        z-index: -1;

                    }
					
					p:last-of-type{margin-bottom: 0.75em;}
					
					a.button:first-of-type {animation-delay: 1s;}
					a.button {animation-delay: 1.5s;}
										
                }

                .slick-slide.slick-active  {


                    img {
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(1.05);
                        -webkit-transform: translate(-50%, -50%) scale(1.05);
                        -ms-transform: translate(-50%, -50%) scale(1.05);
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                    }
					
					
										
                }


				.slideshow_caption {opacity:0; visibility:hidden;display:none; @include transition(all 0.5s ease-in-out); position: relative;}
				.slideshow_caption.activate {background: rgba(255, 255, 255,0); opacity:1; visibility: visible;display:block; text-align: center; @include transition(all 0.5s ease-in-out);}
                .slideshow_caption p.h5 { top: -15%; @include center(true, false);}
				
            }
        }
			
    }
	

    /************************
RESPONSIVE VIDEO WRAPPER
************************/
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    /*********************
CUSTOMISE colorbox
*********************/
    #cboxCurrent, #cboxNext, #cboxNext, #cboxPrevious, #cboxClose {
        top: 4px;
    }

    #cboxCurrent {
        font-size: 120%;
        line-height: 1.8em;
    }

    #cboxLoadedContent {
        margin-top: 28px;
    }
    /*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        position: fixed;
        bottom: 3em;
        right: 0.5em;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        background:darken($color-tertiary, 5%);
        visibility: hidden;
        opacity: 0;
        @include transition(opacity .3s 0s, visibility 0s .3s);
        border-radius:50%;


        i {
            margin: auto;
            line-height: 2.5;
            @include center();
        }
    }

    .cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
        @include transition(opacity .3s 0s, visibility 0s .3s);
    }

    .cd-top.cd-is-visible {
        /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    .cd-top.cd-fade-out {
        /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        opacity: .5;
    }

    .no-touch .cd-top:hover {
        background-color: #e86256;
        opacity: 1;
    }


/*********************
Sticky CTA
*********************/

#stickyCTA {
	position: fixed; bottom:0em; z-index:999; text-align:center; width:100%;
	
	.button.cta {
		@include transition(all 0.4s ease-in-out);
        /*turn off button styling for mobile*/
        width:100%;
        border-radius:0;


		&.active {border-radius:5px 5px 0px 0px;}
	}
	
	.holdForm {
		background-color: $black;
		text-align:center;
		height:1px; width: 100%;
		overflow:hidden;
		opacity:0;
		padding:0;
		@include transition(all 0.4s ease-in-out);
	
		&.active {padding: 1em; height: 270px; width: 100%; opacity:1; @include transition(all 0.4s ease-in-out);}

         ul {text-align:left; margin-bottom: 1em; width: auto; margin-left: auto; margin-right: auto; display: inline-block; padding-left: 0;}
	}
	
	&.active { }
}

/* OVERWRITES NEEDED FOR HISITE BOOKING FORM */
#widgetForm {text-align:left;}
#HDbookingWidget { 
    padding: 0em;
    margin: 0 auto;
    width: auto;
    > div { 
        display: inline-block; position:relative; margin-right: 0.5em; margin-bottom: 0.5em; width:45%;
        &:before {
            display:block;
            content: "\f073";
            font-family: FontAwesome;
            position: absolute;
            width: 32px; height: 32px;
            bottom: 0;
            right: 0;
            z-index: -1;
        }
    }
    > div label { display: block; padding-right: 0.25em; }
    > div input[type=text],  > div select{ 
        padding:0.75em 0.75em; background-color: transparent; border:1px solid darken($color-secondary, 40%); border-radius:0; width:100%; min-width: 100px; cursor: pointer; 
        &:focus {border-color: $color-secondary}
    }
    > div select {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none; 
                 }


    > div:nth-of-type(3), > div:nth-of-type(4) { 
        &:before {
             content: "\f107";
        }
    }
    > div:last-of-type { 
      
        &:before {
            display:none;
        }
    }

} 
/*#HDbookingWidget input, #HDbookingWidget select { float: right; width: 50%; max-width: 250px; padding: 3px 6px;}*/
#HDsubmitBookingWidget { float: right; clear: both; }   

/* seperator styles*/


svg.separator {
	display: block;
    position:absolute;bottom:0;
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 5vw; /* set height to pixels if you want angle to change with screen width */
}

#bottomBlocks svg.separator {top:-1px;}

/* Big triangle with shadow */
#bigTriangleShadow {background-color: $black;}


#trianglePath1 {
	fill:  lighten($color-primary, 0%);
	stroke: lighten($color-primary, 0%);
} 

#trianglePath2 {
	fill: darken($black, 5%);
	stroke: darken($black, 5%);
}

